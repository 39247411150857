@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-display: swap;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: url('assets/fonts/titillium-web-v17-latin-300.woff2') format('woff2');
  }
@font-face {
    font-display: swap;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/titillium-web-v17-latin-regular.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: url('assets/fonts/titillium-web-v17-latin-600.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    src: url('assets/fonts/titillium-web-v17-latin-700.woff2') format('woff2');
}
@font-face {
    font-display: swap;
    font-family: 'Dancing Script';
    font-style: normal;
    font-weight: 400;
    src: url('assets/fonts/dancing-script-v25-latin-regular.woff2') format('woff2');
}
html, body {
    font-family: 'Titillium Web', sans-serif;
    font-size: 1.1rem;
    line-height: 1.6rem;
    font-weight: 300;
}
* {
    scroll-behavior: smooth;
}

strong {
    font-weight: 700;
}
b {
    font-weight: 600;
}